export const StatsSVG = () => {
  return (
    <svg
      id="statistics_1_"
      data-name="statistics (1)"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="15"
      height="15"
      viewBox="0 0 15 15"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Clip_2"
            data-name="Clip 2"
            d="M0,0H4.285V9.109H0Z"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_3" data-name="Group 3" transform="translate(0 5.891)">
        <path
          id="Clip_2-2"
          data-name="Clip 2"
          d="M0,0H4.285V9.109H0Z"
          fill="none"
        />
        <g id="Group_3-2" data-name="Group 3" clipPath="url(#clip-path)">
          <path
            id="Fill_1"
            data-name="Fill 1"
            d="M.537,0H3.75a.536.536,0,0,1,.537.537V8.572a.536.536,0,0,1-.537.537H.537A.536.536,0,0,1,0,8.572V.537A.538.538,0,0,1,.537,0"
            transform="translate(-0.002)"
          />
        </g>
      </g>
      <path
        id="Fill_4"
        data-name="Fill 4"
        d="M.537,0H3.75a.536.536,0,0,1,.537.537V14.463A.536.536,0,0,1,3.75,15H.537A.536.536,0,0,1,0,14.463V.534A.536.536,0,0,1,.537,0"
        transform="translate(5.355)"
      />
      <path
        id="Fill_5"
        data-name="Fill 5"
        d="M.537,0H3.75a.536.536,0,0,1,.537.537v9.642a.536.536,0,0,1-.537.537H.537A.536.536,0,0,1,0,10.179V.537A.534.534,0,0,1,.537,0"
        transform="translate(10.712 4.284)"
      />
    </svg>
  );
};

export const ReviewSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path
        id="Fill_1"
        data-name="Fill 1"
        d="M17.953,6.8a.965.965,0,0,0-.823-.686l-5.2-.492L9.881.606a.942.942,0,0,0-1.76,0L6.067,5.623l-5.2.492A.968.968,0,0,0,.047,6.8,1.028,1.028,0,0,0,.326,7.862l3.928,3.594L3.1,16.779a1.021,1.021,0,0,0,.372,1.031.922.922,0,0,0,1.052.049L9,15.063l4.48,2.8a.923.923,0,0,0,1.052-.049,1.02,1.02,0,0,0,.372-1.031l-1.158-5.323,3.928-3.594A1.028,1.028,0,0,0,17.953,6.8Z"
      />
    </svg>
  );
};

export const ProfileSVG = () => {
  return (
    <svg
      id="suitcase_1_"
      data-name="suitcase (1)"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="14"
      viewBox="0 0 18 14"
    >
      <path
        id="Fill_1"
        data-name="Fill 1"
        d="M5.25,3.15a.727.727,0,0,1-.75-.7V1.4h-3V2.45a.727.727,0,0,1-.75.7A.727.727,0,0,1,0,2.45V1.4A1.454,1.454,0,0,1,1.5,0h3A1.454,1.454,0,0,1,6,1.4V2.45A.727.727,0,0,1,5.25,3.15Z"
        transform="translate(6)"
      />
      <path
        id="Fill_3"
        data-name="Fill 3"
        d="M9.532,2.632A1.642,1.642,0,0,1,9,2.716a1.751,1.751,0,0,1-.578-.1L0,0V5.341A2,2,0,0,0,2.063,7.266H15.938A2,2,0,0,0,18,5.341V0Z"
        transform="translate(0 6.734)"
      />
      <path
        id="Fill_4"
        data-name="Fill 4"
        d="M18,1.925v1.6L9.18,6.272a.592.592,0,0,1-.36,0L0,3.528v-1.6A2,2,0,0,1,2.063,0H15.938A2,2,0,0,1,18,1.925Z"
        transform="translate(0 2.1)"
      />
    </svg>
  );
};

export const WebsiteSVG = () => {
  return (
    <svg
      id="global_1_"
      data-name="global (1)"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="18.04"
      height="18.04"
      viewBox="0 0 18.04 18.04"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Clip_9"
            data-name="Clip 9"
            d="M0,0H15.534V8.435H0Z"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <path
            id="Clip_18"
            data-name="Clip 18"
            d="M0,0H6.776V8.436H0Z"
            fill="none"
          />
        </clipPath>
      </defs>
      <path
        id="Fill_1"
        data-name="Fill 1"
        d="M0,4.165C1.38,3.969,2.589,2.526,3.35.4A16.347,16.347,0,0,0,0,0Z"
        transform="translate(9.341 13.875)"
      />
      <path
        id="Fill_2"
        data-name="Fill 2"
        d="M.282,3.273c.082-.024.163-.047.243-.074s.135-.046.2-.069.158-.056.237-.086.134-.053.2-.08l.229-.1c.066-.029.132-.06.2-.091s.148-.071.222-.108l.193-.1q.108-.058.215-.119c.065-.037.127-.073.19-.111l.209-.129q.093-.059.185-.121c.068-.046.135-.094.2-.142l.178-.129.2-.153c.057-.046.115-.092.171-.139s.126-.109.189-.165.11-.1.164-.147.122-.117.181-.176.1-.1.155-.155L4.27.852A10.819,10.819,0,0,0,2.061,0,8.02,8.02,0,0,1,0,3.353l.078-.019C.147,3.314.215,3.293.282,3.273Z"
        transform="translate(11.263 14.428)"
      />
      <path
        id="Fill_3"
        data-name="Fill 3"
        d="M4.517,0H.651A16.784,16.784,0,0,1,0,4.469a11.207,11.207,0,0,1,2.447.976A9.035,9.035,0,0,0,4.517,0Z"
        transform="translate(13.523 9.343)"
      />
      <path
        id="Fill_4"
        data-name="Fill 4"
        d="M0,4.311H4.188A16.187,16.187,0,0,0,3.553,0,16.955,16.955,0,0,1,0,.425Z"
        transform="translate(9.341 4.386)"
      />
      <path
        id="Fill_5"
        data-name="Fill 5"
        d="M0,0V4.165a16.349,16.349,0,0,0,3.35-.4C2.589,1.639,1.38.2,0,0Z"
        transform="translate(9.341)"
      />
      <path
        id="Fill_6"
        data-name="Fill 6"
        d="M0,3.886a16.971,16.971,0,0,1,3.553.425A16.187,16.187,0,0,0,4.188,0H0Z"
        transform="translate(9.341 9.343)"
      />
      <path
        id="Fill_7"
        data-name="Fill 7"
        d="M2.447,0A11.207,11.207,0,0,1,0,.976,16.784,16.784,0,0,1,.651,5.445H4.517A9.038,9.038,0,0,0,2.447,0Z"
        transform="translate(13.523 3.252)"
      />
      <g id="Group_11" data-name="Group 11" transform="translate(0 0.262)">
        <path
          id="Clip_9-2"
          data-name="Clip 9"
          d="M0,0H15.534V8.435H0Z"
          fill="none"
        />
        <g id="Group_11-2" data-name="Group 11" clipPath="url(#clip-path)">
          <path
            id="Fill_8"
            data-name="Fill 8"
            d="M4.273,2.5l-.028-.03c-.051-.053-.1-.1-.156-.155s-.12-.119-.181-.176-.109-.1-.164-.147-.125-.112-.189-.165-.113-.092-.17-.138-.131-.1-.2-.154-.117-.087-.177-.129-.135-.1-.2-.143-.122-.08-.183-.119-.139-.089-.21-.132L2.227.9Q2.119.84,2.01.781c-.064-.032-.129-.067-.193-.1S1.667.608,1.591.572s-.13-.061-.2-.09-.153-.064-.23-.1S1.031.331.964.306.807.248.727.22.593.173.525.151.364.1.281.077C.214.057.146.037.079.019L0,0A8.022,8.022,0,0,1,2.061,3.353,10.808,10.808,0,0,0,4.273,2.5Z"
            transform="translate(11.261)"
          />
          <path
            id="Fill_10"
            data-name="Fill 10"
            d="M0,5.445H3.866A16.791,16.791,0,0,1,4.517.976,11.193,11.193,0,0,1,2.07,0,9.036,9.036,0,0,0,0,5.445Z"
            transform="translate(-0.002 2.99)"
          />
        </g>
      </g>
      <path
        id="Fill_12"
        data-name="Fill 12"
        d="M3.35,4.165V0A16.358,16.358,0,0,0,0,.4C.761,2.526,1.971,3.969,3.35,4.165Z"
        transform="translate(5.346 13.875)"
      />
      <path
        id="Fill_13"
        data-name="Fill 13"
        d="M4.188,0H0A16.174,16.174,0,0,0,.635,4.311a16.948,16.948,0,0,1,3.553-.425Z"
        transform="translate(4.509 9.343)"
      />
      <path
        id="Fill_14"
        data-name="Fill 14"
        d="M3.35,0C1.971.2.761,1.639,0,3.77a16.327,16.327,0,0,0,3.35.4Z"
        transform="translate(5.346)"
      />
      <path
        id="Fill_15"
        data-name="Fill 15"
        d="M4.188.425A16.955,16.955,0,0,1,.635,0,16.174,16.174,0,0,0,0,4.311H4.188Z"
        transform="translate(4.509 4.386)"
      />
      <path
        id="Fill_16"
        data-name="Fill 16"
        d="M4.269,0,4.191.019c-.068.018-.136.039-.2.058s-.161.047-.243.073S3.609.2,3.541.22s-.158.056-.236.085-.134.053-.2.08l-.229.1c-.067.029-.132.06-.2.091s-.149.071-.222.108l-.194.1Q2.154.841,2.047.9t-.19.111c-.07.042-.139.086-.208.129s-.124.08-.185.121-.135.094-.2.142l-.178.129c-.066.05-.129.1-.2.153s-.115.091-.171.139-.126.109-.188.164-.111.1-.165.148-.121.117-.181.176-.1.1-.156.155L0,2.5a10.807,10.807,0,0,0,2.209.852A8.017,8.017,0,0,1,4.269,0Z"
        transform="translate(2.504 0.261)"
      />
      <g id="Group_20" data-name="Group 20" transform="translate(0 9.343)">
        <path
          id="Clip_18-2"
          data-name="Clip 18"
          d="M0,0H6.776V8.436H0Z"
          fill="none"
        />
        <g id="Group_20-2" data-name="Group 20" clipPath="url(#clip-path-2)">
          <path
            id="Fill_17"
            data-name="Fill 17"
            d="M.181,1.037c.06.059.12.119.181.177s.109.1.164.146.125.112.189.165.113.092.17.138.129.1.2.155.117.086.176.129.135.1.2.143.122.08.183.12.139.089.21.131l.188.11c.072.041.144.081.217.12s.129.067.194.1.149.074.225.109l.2.09c.076.034.153.064.231.1s.132.054.2.079.157.058.237.086.134.047.2.069.161.05.244.074c.067.02.134.04.2.058l.079.019A8.022,8.022,0,0,1,2.209,0,10.806,10.806,0,0,0,0,.852l.028.03C.076.935.128.985.181,1.037Z"
            transform="translate(2.506 5.083)"
          />
          <path
            id="Fill_19"
            data-name="Fill 19"
            d="M2.07,5.445a11.209,11.209,0,0,1,2.447-.976A16.791,16.791,0,0,1,3.866,0H0A9.037,9.037,0,0,0,2.07,5.445Z"
            transform="translate(-0.002)"
          />
        </g>
      </g>
    </svg>
  );
};

export const EmailSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.04"
      height="18.04"
      viewBox="0 0 18.04 18.04"
    >
      <path
        id="arroba"
        d="M9.049,18.04H9.034a9.414,9.414,0,0,1-1.21-.078,9.069,9.069,0,0,1-7.74-7.745A9,9,0,0,1,7.791.085,9.158,9.158,0,0,1,9.028,0a9.039,9.039,0,0,1,8.515,11.992,3.011,3.011,0,0,1-2.83,1.986h-.028a3.058,3.058,0,0,1-2.73-1.719,4.35,4.35,0,1,1-.314-6.684V5.567a.872.872,0,0,1,1.744,0v5.375a1.3,1.3,0,0,0,1.3,1.3h.03a1.266,1.266,0,0,0,1.18-.816A7.25,7.25,0,0,0,9.986,1.859,7.249,7.249,0,0,0,6.112,15.688a7.288,7.288,0,0,0,6.658-.425.873.873,0,0,1,1.2.3.87.87,0,0,1-.3,1.194A8.945,8.945,0,0,1,9.049,18.04Zm-.024-11.6a2.616,2.616,0,1,0,1,.2A2.6,2.6,0,0,0,9.025,6.438Z"
        transform="translate(0.001 0)"
      />
    </svg>
  );
};

export const PhoneSVG = () => {
  return (
    <svg
      id="phone-receiver-silhouette"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16.4"
      height="17.642"
      viewBox="0 0 16.4 17.642"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Clip_2"
            data-name="Clip 2"
            d="M0,0H16.4V17.642H0Z"
            fill="none"
          />
        </clipPath>
      </defs>
      <path
        id="Clip_2-2"
        data-name="Clip 2"
        d="M0,0H16.4V17.642H0Z"
        fill="none"
      />
      <g
        id="phone-receiver-silhouette-2"
        data-name="phone-receiver-silhouette"
        clipPath="url(#clip-path)"
      >
        <path
          id="Fill_1"
          data-name="Fill 1"
          d="M14.6,12.28c-1.065-.911-2.148-1.466-3.2-.555l-.628.552c-.46.4-1.314,2.262-4.622-1.54S4.812,6.346,5.273,5.953L5.905,5.4c1.048-.915.651-2.061-.1-3.244l-.454-.716C4.587.262,3.76-.511,2.712.4L2.144.894A5.333,5.333,0,0,0,.069,4.42c-.38,2.5.814,5.372,3.553,8.519s5.41,4.73,7.944,4.7a5.343,5.343,0,0,0,3.775-1.563l.571-.5c1.045-.911.4-1.836-.673-2.75Z"
        />
      </g>
    </svg>
  );
};

export const InstagramSVG = () => {
  return (
    <svg
      id="instagram"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="18.04"
      height="18.04"
      viewBox="0 0 18.04 18.04"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Clip_2"
            data-name="Clip 2"
            d="M0,0H18.04V18.04H0Z"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_4" data-name="Group 4">
        <path
          id="Clip_2-2"
          data-name="Clip 2"
          d="M0,0H18.04V18.04H0Z"
          fill="none"
        />
        <g id="Group_4-2" data-name="Group 4" clipPath="url(#clip-path)">
          <path
            id="Fill_1"
            data-name="Fill 1"
            d="M4.629,9.259A4.63,4.63,0,1,1,9.257,4.63,4.634,4.634,0,0,1,4.629,9.259Zm0-7.635a3.006,3.006,0,1,0,3,3.005A3.008,3.008,0,0,0,4.629,1.625Z"
            transform="translate(4.394 4.389)"
          />
          <path
            id="Fill_3"
            data-name="Fill 3"
            d="M7.045,18.044h0c-2.266,0-4.111-.112-5.523-1.524C-.022,14.976-.011,12.995.005,10c0-.315,0-.641,0-.979,0-.362,0-.709,0-1.045-.02-2.847-.034-4.9,1.518-6.457A5.321,5.321,0,0,1,5.308.057C6.107.021,7.516,0,9.076,0c1.533,0,2.9.021,3.664.057,3.276.151,5.09,1.965,5.243,5.244.076,1.717.076,5.816,0,7.433-.141,3.064-1.739,4.315-3.056,4.826-1.224.474-2.384.474-5.826.474H9.023c-.363,0-.713,0-1.052.005C7.65,18.042,7.345,18.044,7.045,18.044ZM9,16.409h1.513c2.709,0,3.907-.094,4.852-1.039,1.052-1.057,1.051-2.423,1.048-5.723q0-.3,0-.631c0-2.387-.009-2.675-.052-3.644-.073-1.621-.588-2.631-1.619-3.18a8.226,8.226,0,0,0-3.784-.579c-.3,0-.6,0-.922.006s-.668.006-1.012.006L8.991,1.6H8.94c-2.336,0-2.646.011-3.565.043H5.341c-1.757.09-2.81.686-3.313,1.875a12.022,12.022,0,0,0-.435,4.424c0,.343.006.7.006,1.061,0,.428,0,.832-.009,1.223-.027,2.438-.044,4.047,1.045,5.132,1,1.006,2.38,1.048,5.4,1.048H9Z"
            transform="translate(0)"
          />
        </g>
      </g>
      <path
        id="Fill_5"
        data-name="Fill 5"
        d="M2.2,1.1A1.1,1.1,0,1,1,1.1,0,1.1,1.1,0,0,1,2.2,1.1Z"
        transform="translate(12.669 3.167)"
      />
    </svg>
  );
};

export const BeautySVG = () => {
  return (
    <svg
      id="lotus"
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="22"
      viewBox="0 0 23 22"
    >
      <path
        id="Fill_1"
        data-name="Fill 1"
        d="M3.147,17.44l.508-.663a13.638,13.638,0,0,0,0-16.127L3.147,0,2.639.649a13.638,13.638,0,0,0,0,16.127Z"
        transform="translate(8.353)"
        fill="#fff"
      />
      <path
        id="Fill_2"
        data-name="Fill 2"
        d="M18.9,0c-2.838,2.171-5.172,2.253-7.4,2.434A12.826,12.826,0,0,1,4.1,0C1.622.564,0,1.4,0,2.341c0,1.7,5.174,3.07,11.5,3.07S23,4.037,23,2.341C23,1.4,21.377.563,18.9,0Z"
        transform="translate(0 16.589)"
        fill="#fff"
      />
      <path
        id="Fill_3"
        data-name="Fill 3"
        d="M4.632.577A14.738,14.738,0,0,1,0,11.807C4.244,10.389,7.446,6.067,7.759.878L7.811,0,7.04.06A9.6,9.6,0,0,0,4.632.577Z"
        transform="translate(15.189 5.085)"
        fill="#fff"
      />
      <path
        id="Fill_4"
        data-name="Fill 4"
        d="M7.812,11.807A14.739,14.739,0,0,1,3.18.577,9.589,9.589,0,0,0,.771.06L0,0,.053.878C.365,6.067,3.568,10.389,7.812,11.807Z"
        transform="translate(0 5.085)"
        fill="#fff"
      />
      <path
        id="Fill_5"
        data-name="Fill 5"
        d="M0,14.4A13.377,13.377,0,0,0,3.6.835L3.352,0,2.66.392A10.588,10.588,0,0,0,.648,1.89,15.626,15.626,0,0,1,0,14.4Z"
        transform="translate(14.335 1.311)"
        fill="#fff"
      />
      <path
        id="Fill_6"
        data-name="Fill 6"
        d="M4.151,14.4A15.625,15.625,0,0,1,3.5,1.89,10.585,10.585,0,0,0,1.491.392L.8,0,.552.835A13.38,13.38,0,0,0,4.151,14.4Z"
        transform="translate(4.515 1.311)"
        fill="#fff"
      />
    </svg>
  );
};

export const ClockSVG = () => {
  return (
    <svg
      id="clock"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Clip_2"
            data-name="Clip 2"
            d="M0,0H24V24H0Z"
            transform="translate(0)"
            fill="none"
          />
        </clipPath>
      </defs>
      <path
        id="Clip_2-2"
        data-name="Clip 2"
        d="M0,0H24V24H0Z"
        transform="translate(0)"
        fill="none"
      />
      <g id="clock-2" data-name="clock" clipPath="url(#clip-path)">
        <path
          id="Fill_1"
          data-name="Fill 1"
          d="M12,24A12,12,0,1,1,24,12,12.015,12.015,0,0,1,12,24ZM12,5a1,1,0,0,0-1,1v6.5a1,1,0,0,0,.292.707l5,5a1,1,0,0,0,1.414,0,1,1,0,0,0,0-1.414L13,12.086V6A1,1,0,0,0,12,5Z"
          transform="translate(-0.003)"
        />
      </g>
    </svg>
  );
};

export const CalenderSVG = () => {
  return (
    <svg
      id="calendar"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="29"
      height="29"
      viewBox="0 0 29 29"
    >
      <defs>
        <clipPath id="clip-path">
          <path id="Clip_2" data-name="Clip 2" d="M0,0H29V29H0Z" fill="none" />
        </clipPath>
      </defs>
      <path id="Clip_2-2" data-name="Clip 2" d="M0,0H29V29H0Z" fill="none" />
      <g id="calendar-2" data-name="calendar" clipPath="url(#clip-path)">
        <path
          id="Fill_1"
          data-name="Fill 1"
          d="M22.149,29A6.854,6.854,0,1,1,29,22.146,6.862,6.862,0,0,1,22.149,29Zm0-11.441a4.588,4.588,0,1,0,4.588,4.587A4.593,4.593,0,0,0,22.149,17.559ZM13.2,29H4.532A4.537,4.537,0,0,1,0,24.468V6.8A4.537,4.537,0,0,1,4.532,2.265H5.948V1.133a1.133,1.133,0,1,1,2.266,0V2.265h5.1V1.133a1.133,1.133,0,1,1,2.266,0V2.265h5.155V1.133a1.133,1.133,0,1,1,2.265,0V2.265h1.473A4.537,4.537,0,0,1,29,6.8v6.458a1.133,1.133,0,0,1-2.266,0V6.8a2.268,2.268,0,0,0-2.265-2.265H23V5.664a1.133,1.133,0,1,1-2.265,0V4.532H15.578V5.664a1.133,1.133,0,1,1-2.266,0V4.532h-5.1V5.664a1.133,1.133,0,1,1-2.266,0V4.532H4.532A2.268,2.268,0,0,0,2.266,6.8V24.468a2.268,2.268,0,0,0,2.265,2.266H13.2A1.133,1.133,0,1,1,13.2,29Zm10.593-5.72H22.149a1.134,1.134,0,0,1-1.133-1.133V19.824a1.133,1.133,0,0,1,2.266,0v1.19h.509a1.133,1.133,0,1,1,0,2.265Zm-11.782-.4a1.133,1.133,0,1,1,1.132-1.132A1.134,1.134,0,0,1,12.01,22.883Zm-4.928,0A1.133,1.133,0,1,1,8.214,21.75,1.134,1.134,0,0,1,7.081,22.883Zm4.928-4.928a1.133,1.133,0,1,1,1.132-1.133A1.134,1.134,0,0,1,12.01,17.955Zm-4.928,0a1.133,1.133,0,1,1,1.133-1.133A1.134,1.134,0,0,1,7.081,17.955Zm14.784-4.927A1.133,1.133,0,1,1,23,11.894,1.134,1.134,0,0,1,21.865,13.028Zm-4.928,0a1.133,1.133,0,1,1,1.133-1.133A1.134,1.134,0,0,1,16.937,13.028Zm-4.927,0a1.133,1.133,0,1,1,1.132-1.133A1.134,1.134,0,0,1,12.01,13.028Zm-4.928,0a1.133,1.133,0,1,1,1.133-1.133A1.134,1.134,0,0,1,7.081,13.028Z"
          transform="translate(-0.003)"
        />
      </g>
    </svg>
  );
};
